<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Check from '@/components/api/check'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Check
  },
  data() {
    return {
      title: this.$t('심리스 테스트'),
    };
  },
  methods: {

  },
  mounted() {

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <Check />
  </Layout>
</template>
